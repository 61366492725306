// import React, { useState } from 'react';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Masthead from '../components/masthead';
import Tiles from '../components/tiles';
import SectionTestimony from '../components/testimonial';

const StyledCommercial = styled.div`
	.filterbar {
		.content-holder {
			background-color: #f26a21;
			color: #fff;
			padding: 4rem 0;
			span {
				font-family: ${(p) => p.theme.fonts.title};
				font-size: 2rem;
				font-weight: 700;
			}
			.dropdown {
				position: relative;
				margin: 0 1rem;
				top: 5px;
				::before {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					right: 0;
					top: 10px;
					display: block;
					/*   left width */
					border-left: 5px solid transparent;
					/*   right width */
					border-right: 5px solid transparent;
					/*   height */
					border-top: 10px solid #fff;
				}
				::after {
					content: '';
					position: absolute;
					bottom: -5px;
					width: 100%;
					background-color: #fff;
					height: 2px;
				}
				select {
					background: none;
					border: none;
					color: #fff;
					font-size: 1.5rem;
					font-family: ${(p) => p.theme.fonts.body};
					appearance: none;
					margin-right: 1.5rem;

					option {
						background-color: #f26a21;
					}
				}
			}
		}
	}
	.linkbar {
		height: 12rem;
		background-color: ${(p) => p.theme.color.dark};
		display: flex;
		padding: 0 5rem;
		.columns {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			font-size: 1.5rem;
			a {
				background-color: ${(p) => p.theme.color.dark};
				position: relative;
				padding: 0.5rem 0;
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					background-color: #fff;
					height: 2px;
					opacity: 1;
					transition: 0.5s;
				}
				&:hover {
					box-shadow: none;
					&::after {
						opacity: 0;
					}
				}
			}
		}
	}
	@media all and (min-width: 1600px) {
		.linkbar .columns .ec-button {
			font-size: 2rem;
		}

		.filterbar .content-holder {
			span {
				font-size: 2.5rem;
			}
			.dropdown select {
				font-size: 2rem;
			}
		}
	}
	@media all and (max-width: 768px) {
		.filterbar {
			span:first-child {
				display: block;
			}
		}
		@media all and (max-width: 500px) {
			span:last-child {
				margin-top: 1rem;
				display: block;
			}
		}

		.linkbar {
			padding: 0 1rem;
			.columns {
				flex-direction: column;
				.column:first-child {
					display: flex;
					align-items: flex-end;
					margin-bottom: 1rem;
				}
				.column:last-child {
					display: flex;
					align-items: flex-start;
					margin-top: 1rem;
				}
			}
		}
	}
	@media all and (max-width: 400px) {
		.linkbar a {
			padding: 0.5rem 0;
		}
	}
`;

export default ({ data }) => {
	const {
		mast,
		// filterbar,
		tilesDiv,
		linkbar,
		testimony,
	} = data.allMarkdownRemark.edges[0].node.frontmatter;

	// const [filteredTiles, setTiles] = useState(tilesDiv.tiles);

	// const handleSelect = (e) => {
	// 	let filter = e.target.value;
	// 	let newTiles = [];

	// 	if (filter === 'all') {
	// 		setTiles(tilesDiv.tiles);
	// 	} else {
	// 		for (let i in tilesDiv.tiles) {
	// 			if (e.target.value === tilesDiv.tiles[i].category) {
	// 				newTiles.push(tilesDiv.tiles[i]);
	// 			}
	// 		}
	// 		setTiles(newTiles);
	// 	}
	// };

	return (
		<StyledCommercial>
			<Masthead title={mast.title} image={mast.img} />

			{/* <div className="filterbar">
				<div className="content-holder has-text-centered">
					<span>{filterbar.text1}</span>
					<span className="dropdown">
						<select onChange={(e) => handleSelect(e)}>
							{filterbar.options.map((x, i) => (
								<option value={x.value} key={i}>
									{x.text}
								</option>
							))}
						</select>
					</span>
					<span>{filterbar.text2}</span>
				</div>
			</div> 
			
			<Tiles tiles={filteredTiles} bimg={tilesDiv.bimg} page="commercial" />*/}

			<Tiles tiles={tilesDiv.tiles} bimg={tilesDiv.bimg} page="commercial" />

			<div className="linkbar">
				<div className="columns is-gapless is-desktop has-text-centered">
					<div className="column is-half">
						<div className="twin-holder">
							<Link to={linkbar.contact.link} className="ec-button">
								{linkbar.contact.text}
							</Link>
						</div>
					</div>
					<div className="column is-half">
						<div className="twin-holder">
							<Link to={linkbar.personal.link} className="ec-button">
								{linkbar.personal.text}
							</Link>
						</div>
					</div>
				</div>
			</div>

			<SectionTestimony data={testimony} />
		</StyledCommercial>
	);
};
