import React from 'react';
import Layout from '../components/layout';
import CommercialTemplate from '../templates/commercial-template';
import SEO from '../components/seo';
import { graphql } from 'gatsby';

const Commercial = ({ data }) => {
	return (
		<Layout page="Commercial">
			<SEO
				title="Commercial"
				description={
					data.allMarkdownRemark.edges[0].node.frontmatter.description
				}
			/>
			<CommercialTemplate data={data} />
		</Layout>
	);
};

export default Commercial;

export const query = graphql`
	{
		allMarkdownRemark(filter: { frontmatter: { slug: { eq: "commercial" } } }) {
			edges {
				node {
					frontmatter {
						description
						mast {
							title
							img
						}
						filterbar {
							text1
							text2
							options {
								text
								value
							}
						}
						tilesDiv {
							bimg
							tiles {
								title
								goto
								content
								category
							}
						}
						linkbar {
							contact {
								text
								link
							}
							personal {
								text
								link
							}
						}
						testimony {
							author
							location
							content
						}
					}
				}
			}
		}
	}
`;
